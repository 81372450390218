@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Exo2", "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: none;
  text-rendering: optimizeSpeed;
  font-display: swap;
  image-rendering: optimizeSpeed;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

span {
  font-family: "Exo2", "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#about,
#services,
#photos {
  scroll-margin-top: 100px;
}

#socials {
  scroll-margin-top: 70px;
}

#content {
  scroll-margin-top: 70px;
}

.header-title {
  font-family: "Exo 2", sans-serif;
  font-weight: 800;
  line-height: 40px;
  text-transform: uppercase;
}

p {
  font-family: "Exo 2", sans-serif;
}

.sub-headers {
  font-family: "Exo 2", sans-serif;
  font-weight: 800;
}

.image1 {
  -webkit-clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
  clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
  left: 0;
  top: 0;
}

.image2 {
  -webkit-clip-path: polygon(60% 0, 100% 0%, 100% 100%, 40% 100%);
  clip-path: polygon(60% 0, 100% 0%, 100% 100%, 40% 100%);
  left: 0;
  top: 0;
}

@media screen and (min-width: 600px) {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 208, 255);
    border-radius: 4px;
  }
}

.nav-link {
  transition: all 100ms ease-in;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-link:hover {
  color: aqua;
  transform: scale(1.03);
}

.template-shadow {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
}
.template-shadow-subtle {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.template-shadow-dark {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.32);
}

.text-shadow {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.text-shadow-subtle {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

/* Old Button */
/* .contact-now {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1a1a1a;
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
} */

/* 
<!-- HTML !-->
<button class="button-19" role="button">Button 19</button>

/* CSS */
.contact-now {
  appearance: button;
  background-color: #06b6d4;
  border: solid transparent;
  border-radius: 2px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: din-round, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 12px 24px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  transition: ease-in-out 170ms;
}

.contact-now:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.contact-now:main,
.contact-now:focus {
  user-select: auto;
}

.contact-now:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.contact-now:disabled {
  cursor: auto;
}

.contact-now:active {
  border-width: 4px 0 0;
  background: none;
}

.quote-now {
  appearance: none;
  background: rgb(0, 208, 255);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 15px;
}

.quote-now:hover,
.contact-now:hover {
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 15px;
}

.quote-now:active,
.contact-now:active {
  opacity: 1;
}

.header-button {
  margin-top: 50px;
}

.services-tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 8px;
  background: rgba(0, 0, 0, 0.25);
  padding: 72px 0;
  border-radius: 2px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
}

.services-header {
  padding-top: 20px;
  font-weight: bold;
  font-size: 20px;
  color: white;
  text-align: center;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
}

.services-quote {
  margin-top: 20px;
  color: white;
  text-decoration: underline;
  text-underline-offset: 6px;
  transition: all 250ms ease-in;
}

.services-quote:hover {
  scale: 1.08;
  color: white;
}

.photos-container {
  height: 450px !important;
  margin: 8px;
  border-radius: 6px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);
}

.gallery-container {
  height: 500px;
  margin: 8px;
  border-radius: 6px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);
}

.tabs-container {
  display: flex;
}

/* BURGER BAR */

.navLeft__burger {
  display: none;
}

.navLeft__logo {
  height: 80px;
  width: 120px;
  display: flex;
}

.burger__logo {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.burger__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 8px;
}

.burger__list--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-top: 30px;
  margin-bottom: 40px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.burger__list--wrapper:hover {
  transform: scale(1.03);
}

.burger__links--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-left: 0;
}

.burger__links {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 0px;
}

.burger__list--header {
  font-size: 20px;
  font-weight: 700;
}

.burger__list--header:active,
.burger__list--header:hover {
  color: aqua;
}

.burger__link {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 900;
  cursor: pointer;
}

.instagram {
  color: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

/*  */

@media screen and (max-width: 500px) {
  .services-header {
    font-size: 18px;
    padding: 20px 4px 0 4px;
  }
  .photos-container {
    height: 250px !important;
  }
  .photos-container > img {
    height: 250px !important;
  }

  .mobile {
    padding: 14px 20px;
    font-size: 14px;
  }
  .services-tabs {
    height: 225px;
  }
}

@media screen and (max-width: 768px) {
  .services-tabs {
    padding: 36px 0;
  }
  .photos-container {
    height: 300px;
    margin: 8px 24px;
  }
  .photos-container > img {
    height: 300px;
  }

  #about,
  #services,
  #quotes,
  #photos {
    scroll-margin-top: 20px;
  }

  #quotes {
    scroll-margin-top: -10px;
  }
}

@media screen and (max-width: 1024px) {
  .photos-container {
    height: 350px;
  }
}

@media screen and (min-width: 1280px) {
  .services-tabs {
    padding: 100px 0;
  }
}

.footerLinks {
  @apply hover:underline opacity-80 text-sm md:text-base  text-white space-y-1 py-4;
}
